$row-padding: 10px;

$prefix: '';

@mixin rowItem() {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

@mixin rowLandingPad() {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  overflow: hidden;

  * {
    opacity: 0 !important;
    pointer-events: none;
  }

  &::before {
    background-color: rgba(84, 49, 157, 0.1);
    box-shadow: inset 0 0 0 1px rgba(84, 49, 157, 0.3);
    border-radius: 4px;
    content: '';
    position: absolute;
    top: 0;
    right: 40px;
    bottom: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }
}

@mixin moveHandle() {
  height: 100%;
  width: 44px;
  background-image: none;
  border: solid #aaa 1px;
  box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
}

.#{$prefix}rowWrapper {
  height: 100%;
  box-sizing: border-box;

  &:active {
    opacity: 1;
  }
}

.#{$prefix}rowWrapperDragDisabled {
  pointer-events: none;
  &:hover {
    opacity: 1;
  }
}

.#{$prefix}row {
  height: 100%;
  white-space: nowrap;
  display: flex;

  & > * {
    box-sizing: border-box;
  }
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.#{$prefix}rowLandingPad {
  @include rowLandingPad;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.#{$prefix}rowCancelPad {
  @include rowLandingPad;

  &::before {
    background-color: #f0d3d3;
  }
}

.#{$prefix}rowContents {
  @include rowItem;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.#{$prefix}rowLabel {
  @include rowItem;
  width: 100%;
  flex-grow: 1;
}

.#{$prefix}rowToolbar {
  @include rowItem;
  flex: 0 1 auto;
  display: flex;
}

.#{$prefix}moveHandle {
  @include rowItem;
  @include moveHandle;
}

.#{$prefix}loadingHandle {
  @include moveHandle;
  background: #d9d9d9;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.#{$prefix}loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.#{$prefix}loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  $point-count: 12;
  $spin-animation-time: 800ms;

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 11%;
    height: 30%;
    background-color: #fff;
    border-radius: 30%;
    animation: pointFade $spin-animation-time infinite ease-in-out both;
  }

  // not being used + throwing compiling errors in later versions ing Dart Sass
  // @for $i from 1 through (($point-count + 1) / 2) {
  //   &:nth-of-type(#{$i}) {
  //     transform: rotate(360deg / $point-count * ($i - 1));
  //   }

  //   &:nth-of-type(#{$i + $point-count / 2}) {
  //     transform: rotate(180deg + 360deg / $point-count * ($i - 1));
  //   }

  //   &:nth-of-type(#{$i}),
  //   &:nth-of-type(#{$i + $point-count / 2}) {
  //     &:before {
  //       animation-delay: -$spin-animation-time +
  //         ($spin-animation-time / $point-count * 2 * ($i - 1));
  //     }
  //   }
  // }
}

.#{$prefix}toolbarButton {
  @include rowItem;
}

.#{$prefix}rowTitle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.#{$prefix}rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.#{$prefix}rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.#{$prefix}collapseButton,
.#{$prefix}expandButton {
  appearance: none;
  border: none;
  position: absolute;
  border-radius: border-radius(small);
  padding: 0;
  top: 50%;
  cursor: pointer;
  line-height: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  opacity: var(--ks-nav-icon-opacity);
  color: #4c5566;
  transform: translate(-50%, -50%);
  left: 50% !important;
  right: 0;
  padding-right: 0.5rem;

  > * {
    transition: transform 0.1s ease, opacity 0.1s ease;
  }
}

.#{$prefix}collapseButton {
  > * {
    transform: rotate(180deg) scale(var(--ks-nav-icon-scale));
  }

  &:hover:not(:active) {
    opacity: 1;

    > * {
      transform: scale(1.1) rotate(180deg) scale(var(--ks-nav-icon-scale));
    }
  }
}

.#{$prefix}expandButton {
  > * {
    transform: rotate(0deg) scale(var(--ks-nav-icon-scale));
  }

  &:hover:not(:active) {
    opacity: 1;

    > * {
      transform: scale(1.1) rotate(0deg) scale(var(--ks-nav-icon-scale));
    }
  }
}

/**
 * Line for under a node with children
 */
.#{$prefix}lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;

  &::after {
    content: '';
    position: absolute;
    background-color: black;
    width: 1px;
    left: 50%;
    bottom: 0;
    height: $row-padding;
  }
}
