.ks-side-nav-item {
  margin-bottom: 0.25rem;
  background-color: transparent;
  color: #2e323a;
  display: flex;
  align-items: center;

  &--group {
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
  }

  .ks-popover__children {
    margin-left: 8px;
    & + .ks-popover__children {
      margin-left: 2px;
    }
  }
}

.ks-c-legacy-nav-item__toggle-icon {
  transform: rotate(-180deg) scale(var(--ks-nav-icon-scale, 1));
  transition: transform 150ms ease, opacity 150ms ease;
  color: var(--knapsack-custom-theme--secondary-nav--text-color, currentColor);
  opacity: var(--ks-nav-icon-opacity, 1);

  &.ks-c-legacy-nav-item__toggle-icon--collapsed {
    transform: rotate(0deg) scale(var(--ks-nav-icon-scale, 1));
  }
}

.ks-c-legacy-nav-item__toggle-icon-button {
  color: var(--knapsack-custom-theme--secondary-nav--text-color, currentColor);
}

.ks-c-nav-row:hover .ks-c-legacy-nav-item__toggle-icon {
  transform: rotate(-180deg) scale(1.1);
  opacity: 1;

  &.ks-c-legacy-nav-item__toggle-icon--collapsed {
    transform: rotate(0deg) scale(1.1);
  }
}

.ks-side-nav-item--editing {
  border: 2px solid transparent;

  .ks-side-nav-item__drag-handle {
    display: inline-block;
    vertical-align: middle;
  }

  .ks-side-nav-item__title-container {
    padding-left: 0;
  }

  .ks-popover__children {
    margin-left: 0;
    & + .ks-popover__children {
      margin-left: 2px;
    }
    & + *:not(.ks-popover__children) {
      margin-left: 8px;
    }
  }

  // Using styles to show/hide the edit button in ks-inline-edit so that the
  // edit button shows on hover of the parent item.
  .ks-inline-edit:not(.ks-inline-edit--editing) .ks-inline-edit__controls {
    opacity: 0;
  }

  .ks-side-nav-item__actions {
    opacity: 0;
    margin-left: 4px;
    display: inline-flex;
    align-items: center;
  }

  &:hover,
  &:focus {
    // Using styles to show/hide the edit button in ks-inline-edit so that the
    // edit button shows on hover of the parent item.
    .ks-inline-edit .ks-inline-edit__controls {
      opacity: 1;
    }

    .ks-side-nav-item__actions {
      opacity: 1;
    }
  }
}

.node {
  height: auto !important;
}

.node-current {
  height: 100%;
}

// @todo: WIP styles part of the larger UI refresh
.ks-c-legacy-nav-item {
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  // padding-left:
  /* prettier-ignore */
  --nav-item-dynamic-offset: calc(calc(var(--ks-nav-parent-toggle-count) * var(--ks-nav-indent)) + calc(var(--ks-nav-parent-link-count) * var(--ks-nav-indent)) + #{spacing(small)});
  padding-left: var(--nav-item-dynamic-offset);
  background-color: transparent;
  // margin-left: var(--ks-nav-indent);
  // @include padding-left(medium);
  // @include padding-right(small);
  border-radius: 4px;
  transition: all 0.1s ease;
  -webkit-appearance: none;
  border: 0;
  text-align: left;
  flex-grow: 1;
  cursor: pointer;
  width: 100%;

  &:not(:last-of-type) .ks-c-legacy-nav-item__popup-trigger {
    visibility: visible;
    opacity: 1;
  }

  &:hover > .rstcustom__rowLink:not(.ks-c-legacy-nav-item--active):before {
    opacity: 0.05;
  }
}

.ks-c-nav-row--expandable .ks-c-legacy-nav-item {
  width: calc(100% - var(--nav-offset));
}

// display the "active" background if the popup menu is open
.ks-secondary-nav:not(.is-dragging) .ks-c__row:hover,
.ks-c__rowContents:not(:last-child) {
  .rstcustom__rowToggle:before,
  .rstcustom__rowLink:before {
    opacity: 0.1;
  }

  // .ks-c-legacy-nav-item__popup-trigger {
  //   opacity: 1;
  //   visibility: visible;
  // }
}

// .ks-c-legacy-nav-item__popup-trigger:focus-within {
//   opacity: 1;
//   visibility: visible;
// }

.ks-c-nav-row.is-using-keyboard:focus-within
  .ks-c-legacy-nav-item__popup-trigger {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.ks-c-legacy-nav-item__toggle-icon {
  right: 0.5rem;
  position: absolute;
  cursor: pointer;
  border-radius: 100%;
}

.ks-c-nav-row--expandable .ks-c-legacy-nav-item__popup-trigger {
  right: 1.75rem;
}

.ks-c-nav-row--link {
  color: var(--knapsack-custom-theme--secondary-nav--text-color, currentColor);
}

.ks-c-nav-row--link .ks-c-legacy-nav-item__popup-trigger {
  right: 0.25rem;
}
.rstcustom__rowLink,
.rstcustom__rowToggle {
  padding: 2px 8px;
  // min-height: 30px; // so empty items don't collapse
  touch-action: manipulation;
}

.ks-c-legacy-nav-item__text {
  font-family: var(
    --knapsack-custom-theme--secondary-nav--font-family,
    inherit
  );
  font-size: var(--knapsack-custom-theme--secondary-nav--font-size, 14px);
  line-height: var(--knapsack-custom-theme--secondary-nav--line-height, 1.4);
  font-weight: var(--knapsack-custom-theme--secondary-nav--font-weight, 400);
  letter-spacing: var(--knapsack-custom-theme--secondary-nav--letter-spacing);
  margin-top: 0.05ex;
  padding-block: 4px;
}

.is-using-keyboard .ks-c-nav-row--expandable:focus {
  box-shadow: inset 0 0 0 1px
    var(--knapsack-custom-theme--secondary-nav--accent-color, #54319d);
}

.ks-c-nav-row--toggle {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &:hover:before {
    opacity: 0.05;
  }
}

.is-using-keyboard .rstcustom__rowLink:focus {
  box-shadow: inset 0 0 0 1px
    var(--knapsack-custom-theme--secondary-nav--accent-color, #54319d);
}

.ks-c-nav-row--toggle,
.rstcustom__rowLink {
  width: 100%;
  display: block;
  text-decoration: none;
  text-transform: var(
    --knapsack-custom-theme--secondary-nav--text-transform,
    inherit
  );
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--knapsack-custom-theme--secondary-nav--text-color, #1d2129);
  position: relative;
  border-radius: 4px;

  &::before {
    background-color: var(
      --knapsack-custom-theme--secondary-nav--accent-color,
      #54319d
    );
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    border-radius: 4px;
    pointer-events: none;
  }

  &.ks-c-legacy-nav-item--active:before,
  &:active:before {
    opacity: 0.1;
  }

  &.ks-c-legacy-nav-item--active,
  &:active {
    color: var(--knapsack-custom-theme--secondary-nav--accent-color, #54319d);
  }
}

// temp patch to hide highlighted background of nav groups (toggles) when you aren't logged in, otherwise the bg is highlighted but you can't do anything
.ks-c-legacy-nav-item--read-only .rstcustom__rowToggle {
  pointer-events: none;
}

.ks-c-nav-row--expandable {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ks-c-legacy-nav-item__inner {
  padding-right: 0;
}

.rstcustom__rowLink {
  pointer-events: auto;

  // &:last-child {
  //   margin-right: 40px;
  // }
}

.ks-c__collapseButton,
.ks-c__expandButton {
  &:focus.is-using-keyboard {
    outline: none;
    box-shadow: inset 0 0 0 1px
      var(--knapsack-custom-theme--secondary-nav--accent-color, #54319d);
  }
}

.rst__nodeContent {
  right: 0;
  margin-left: -40px;
}

// nuke nav tree lines
.rst__lineBlock,
.rst__absoluteLineBlock {
  display: none !important;
}

.rstcustom__rowToggle {
  font-weight: 600;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--knapsack-custom-theme--secondary-nav--text-color, #2e323a);
  width: 100%;
}

.ks-c__expandButton:hover,
.ks-c__collapseButton:hover {
  & ~ .ks-c__rowWrapperDragDisabled {
    .rstcustom__rowToggle:before,
    .rstcustom__rowLink:before {
      opacity: 0.1;
    }
  }
}

.rst__nodeContent {
  --ks-nav-icon-scale: 1;
  --ks-nav-icon-opacity: 1;
}

.rst__nodeContent:not([style*='margin-left: 0px']) {
  --ks-nav-icon-scale: 0.8;
  --ks-nav-icon-opacity: 0.6;
}

.ks-c-legacy-nav-item__text {
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.ks-c-legacy-nav-item__icon {
  flex-shrink: 0;
}

.ks-c-legacy-nav-item:focus .ks-c-legacy-nav-item__actions-popup-trigger,
.ks-c-legacy-nav-item:hover .ks-c-legacy-nav-item__actions-popup-trigger,
.ks-c-legacy-nav-item:active .ks-c-legacy-nav-item__actions-popup-trigger {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.rstcustom__rowToggle + .ks-btn,
.rstcustom__rowLink + .ks-btn {
  margin-left: -2.25rem;
  position: relative;
  z-index: 1;
}

// slightly smaller than the default 2px x spacing(small) padding so active search items don't clash (but this is still visually the same)
.ks-c-legacy-nav-item__text-input {
  padding: 3px 8px;
  margin-top: -3px;
  margin-left: -8px;
  margin-bottom: -3px;
  margin-right: -8px;
  border: 0;
  font-weight: var(--knapsack-custom-theme--secondary-nav--font-weight, 400);
  letter-spacing: var(
    --knapsack-custom-theme--secondary-nav--letter-spacing,
    normal
  );
  font-family: var(--knapsack-custom-theme--secondary-nav--font-family);
  background-color: var(--ks-input-bg-color, #ffffff);
  box-shadow: inset 0 0 0 1px
    var(--knapsack-custom-theme--secondary-nav--accent-color, #54319d);
  border-radius: inherit;
  width: 100%;
  position: relative;
  z-index: 1;
  flex-grow: 1;

  &:invalid {
    box-shadow: inset 0 0 0 1px #fc3a43;
  }

  .ks-c-nav-row--toggle & {
    margin-right: 0;
    min-width: calc(100% - 38px);
  }
}

.ks-c-legacy-nav-item:not(.ks-c-legacy-nav-item--read-only):hover
  .ks-c-legacy-nav-item__text,
.ks-c-legacy-nav-item:not(.ks-c-legacy-nav-item--read-only):focus
  .ks-c-legacy-nav-item__text,
.ks-c-legacy-nav-item:not(.ks-c-legacy-nav-item--read-only):active
  .ks-c-legacy-nav-item__text {
  max-width: calc(100% - 3rem);
}
.ks-c-nav-row--toggle
  .ks-c-legacy-nav-item:not(.ks-c-legacy-nav-item--read-only):hover
  .ks-c-legacy-nav-item__text,
.ks-c-nav-row--toggle
  .ks-c-legacy-nav-item:not(.ks-c-legacy-nav-item--read-only):focus
  .ks-c-legacy-nav-item__text,
.ks-c-nav-row--toggle
  .ks-c-legacy-nav-item:not(.ks-c-legacy-nav-item--read-only):active
  .ks-c-legacy-nav-item__text {
  max-width: calc(100% - 4.75rem);
}

.ks-c-legacy-nav-item--hidden {
  opacity: 0.5;
}

.ks-c-legacy-nav-item__private {
  margin-right: 4px;
}

// hide everything that isn't a search result match
.ks-c-global-search__results {
  // collapse extra layers of containers to reduce space between items
  .ks-c-nav-row--expandable,
  .ks-c-legacy-nav-item__inner {
    display: contents;
  }
  .ks-c-nav-row:not(.ks-c-nav-row--is-search-match),
  .ks-c-legacy-nav-item__icon,
  .ks-c-legacy-nav-item__toggle-icon,
  .ks-c-nav-row--toggle {
    display: none;
  }
  .ks-c-nav-row:not(.ks-c-nav-row--expandable),
  .ks-c-nav-row--expandable .ks-c-legacy-nav-item {
    margin-right: 0;
  }
  .rst__nodeContent {
    margin-left: 0 !important;
  }
}

.ks-c-legacy-nav-item__popup-trigger {
  position: absolute;
  top: 0;
  right: 0.25rem;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;

  // progressively enhanced style so the parent container becomes visible if the child element is toggled
  &:has(.is-toggled) {
    visibility: visible;
    opacity: 1;
  }

  > * {
    pointer-events: none;

    .ks-c-legacy-nav-item:focus &,
    .ks-c-legacy-nav-item:active &,
    .ks-c-legacy-nav-item:focus-within &,
    .ks-c-legacy-nav-item:hover & {
      pointer-events: auto;
    }
  }

  .ks-c-legacy-nav-item:focus &,
  .ks-c-legacy-nav-item:active &,
  .ks-c-legacy-nav-item:focus-within &,
  .ks-c-legacy-nav-item:hover & {
    opacity: 1;
    visibility: visible;
  }
}

.ks-c-legacy-nav-item__popup {
  .ks-c-tooltip-trigger {
    display: flex;
  }

  .ks-popover__box-inner {
    background-color: #4c5566;
    padding-top: 0.25rem;
    padding-right: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
  }

  .ks-popover__children {
    width: 100%;
  }

  .ks-c-icon {
    margin-right: 0.25rem;
    opacity: 0.6;
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .ks-l-list-bare__item {
    line-height: 1;
  }

  .ks-btn {
    width: 100%;
    font-weight: 400;
    color: #ffffff;
    border-radius: 4px;
    transition: background 0.1s ease;
    justify-content: flex-start;
    height: auto;
    font-size: 14px;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    flex-shrink: 0;

    &:focus.is-using-keyboard {
      box-shadow: inset 0 0 0 1px #ffffff;
    }

    &:focus.is-using-keyboard,
    &:hover {
      background-color: rgba(#c4c4c4, 0.2);

      .ks-c-icon {
        opacity: 1;
      }
    }
  }
}

.ks-c__row {
  border-radius: 4px;
}
